<!-- 投资记录 -->
<template>
  <div id="Order">
    <van-nav-bar
      :title="$t('m_mime.tzjl')"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <!-- <template #right>
        <i class="iconfont icon-wode-wode"></i>
      </template> -->
    </van-nav-bar>
    <van-empty v-if="false" :description="$t('m_fundsSummary.zwsj')" />
    <!-- 列表 -->
    <div class="o-container">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        :loading-text="$t('common.loading-text')"
        :immediate-check="false"
        @load="onLoadInfo"
      >
        <ul class="c-box">
          <li v-for="(item, index) in list" :key="index">
            <div class="list-item-flex">
              <p class="name">{{ item.product.show_name }}</p>
              <p class="num" v-if="$i18n.locale == 'zh'">
                第<span>{{ item.number }}</span
                >轮
              </p>
              <p class="num" v-else>
                {{ $t("trading.ls") }}<span>{{ item.number }}</span>
              </p>
            </div>
            <div class="list-item-flex">
              <p class="name type">{{ item.content }}</p>
              <p class="num">
                <span>{{ item.zongjine }}</span>
              </p>
            </div>
            <div class="list-item-flex">
              <p class="num state" v-if="item.status == 2">
                {{ $t("m_history.tzsb") }}
              </p>
              <p class="num state red" v-else-if="item.status == 1">
                +{{ item.shouyi | privateMoney}}
              </p>
              <p class="num state" v-else>{{ $t("m_history.dsy") }}</p>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
import { inRecord } from "@/api/investhome";
import { getOrder } from "@/api/user";
export default {
  name: "",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      product_id: -1,
      page: 1,
      limit: 10,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    let option = this.$route.query;
    console.log(option);
    if (option.product_id) {
      // 接收参数
      this.product_id = option.product_id;
      this.getOrderShow();
    } else {
      console.log("1");
      this.getOrderShow();
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      console.log("抽屉");
      /* this.show = true; */
    },
    onLoadInfo() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.page += 1;
      console.log("进来了")
      this.getOrderShow();
      /* setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000); */
    },
    /* 获取投资记录 */
    getOrderShow() {
      getOrder({
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        let result = res.data;
        if (result.length > 0) {
          result.forEach((element) => {
            this.list.push(element);
          });
          // 加载状态结束
          this.loading = false;
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    /* 投资记录 */
    inRecordShow() {
      inRecord({
        product_id: this.product_id,
      }).then((res) => {
        this.list = res.data;
        // 加载状态结束
        this.loading = false;
        this.finished = true;
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  position: fixed;
  width: 100%;
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

#Order {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .o-container {
    padding: 0 0.1rem;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    top: 0.88rem;

    .c-box {
      width: 100%;
      padding-top: 0.36rem;
      box-sizing: border-box;

      li {
        box-sizing: border-box;
        width: 100%;
        height: 1.66rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #524f45;
        border-radius: 0.15rem;
        border: solid 0.02rem #786f52;
        padding: 0.1rem;
        font-weight: 300;
        margin-bottom: 0.15rem;

        .list-item-flex {
          flex: 1;
          text-align: center;

          .name {
            color: #ffffff;
            font-size: 0.3rem;
          }
          .num {
            font-size: 0.26rem;
            color: #fff;
            letter-spacing: 1px;
            line-height: 0.6rem;
            &.red {
              color: #ff4258;
            }
            span {
              color: #eecb77;
            }
          }
        }
      }
    }
  }
}
</style>